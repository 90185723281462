import 'whatwg-fetch';
import Glide from '@glidejs/glide';

new Glide('#store-glide', {
  type: 'carousel'
}).mount();

new Glide('#videos-glide', {
  type: 'carousel'
}).mount();

let wheel = document.getElementById('wheel');
let viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

window.addEventListener('scroll', () => {
  // viewport height is 360deg
  let scrollY = window.scrollY;
  let turns = scrollY / viewportHeight;
  let angle = turns * 360;
  wheel.style.transform = `rotate(${angle}deg)`;
});

let inputs = ['name', 'email', 'message'].map((id) => document.getElementById(id));
let submit = document.getElementById('submit');

inputs.forEach((input) => {
  input.addEventListener('input', () => {
    let isValid = inputs.every((input) => !!input.validity.valid);

    if (isValid) {
      submit.removeAttribute('disabled');
    } else {
      submit.setAttribute('disabled', 'disabled');
    }
  });
  input.addEventListener('blur', () => {
    input.classList.add('touched');
  });
});

let eventsList = document.getElementById('events');

function insertEvent(event) {
  let eventDiv = document.createElement('div');
  eventDiv.classList.add('event');

  let dateDiv = document.createElement('div');
  dateDiv.classList.add('date');
  dateDiv.innerText = event.date;
  eventDiv.appendChild(dateDiv);

  let nameDiv = document.createElement('div');
  nameDiv.classList.add('name');
  nameDiv.innerText = event.name;
  eventDiv.appendChild(nameDiv);

  let countryDiv = document.createElement('div');
  countryDiv.classList.add('country');
  countryDiv.innerText = `${event.city}${event.country ? ', ' + event.country : ''}`;
  eventDiv.appendChild(countryDiv);

  let moreInfoAnchor = document.createElement('a');
  moreInfoAnchor.classList.add('more-info');
  moreInfoAnchor.setAttribute('target', '_blank');
  moreInfoAnchor.setAttribute('rel', 'noopener');
  moreInfoAnchor.setAttribute('href', event.url);
  moreInfoAnchor.innerText = 'More info →';
  eventDiv.appendChild(moreInfoAnchor);

  eventsList.appendChild(eventDiv);
}

function getEvents() {
  let url = 'https://api.songkick.com/api/3.0/artists/4119081/calendar.json?apikey=J8KoWemVet3EUPGZ';
  fetch(url)
    .then((r) => r.json())
    .then((data) => {
      if(data.resultsPage.totalEntries > 0) {
        data.resultsPage.results.event.forEach((e) => {
          let date = new Date(e.start.datetime || e.start.date);

          let name = e.displayName.includes('Fest') ? e.displayName : e.venue.displayName;

          insertEvent({
            date: `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`,
            name,
            city: e.location.city || e.venue.metroArea.displayName,
            country: e.location.city ? '' : e.venue.metroArea.country.displayName,
            url: e.uri
          });
        });
      } else {
        document.querySelector(".section-live").style.display = "none";
      }
    })
    .catch((e) => {
      console.error(e);
    });
}

// old songkick events replaced by bandsintown widget
// getEvents();